/*********************** REEF LANDING  ********************/

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

@font-face {
  font-family: Museo;
  src: url('../webfont/MuseoSansRounded300.otf');
}

/* Variables */
:root {
 --primary-color: #0055CC;
 --text-black: #000000;
 --main-bg: #fff;
 --main-font: Museo, sans-serif;
 --generic-font: 'Merriweather', serif;
 --btn-font: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* Necessaries and Basics */
body {
  margin: 0;
  color: var(--text-black);
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, textarea, select {
  font-family: var(--main-font);
  outline: none;
  border: none;
}

input{
  font-size: 14px;
}


button {
  padding:0.9rem 3rem;
  border-radius:6px;
  font-family: var(--btn-font);
  font-weight: 500;
}

button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.navigation-text {
  font-weight: 100;
  font-size: 14px;
  color: var(--main-bg);
  padding:0 0 0.2rem 0;
  cursor: pointer;
}

@media (max-width:900px){
  .navigation-text {
    color: var(--text-black);
  }
  input{
    font-size: 16px;
  }
}

.navigation-text:hover {
  opacity:0.8;
}
.active {
  font-weight:500;
  border-bottom:0.5px solid #fff;
}

/* General Style */
.main-widget {
  width:100%;
  margin:0 auto;
  padding: 0;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
  .main-widget{
    width:100%;
  }
}

@media only screen and (min-width: 700px) {
  .main-widget > div{
    width:80%;
    padding:0 10%;
  }
}

@media only screen and (min-width: 1100px) {
  .main-widget > div{
    width:80%;
    padding: 4rem 10% 0 10%;
  }
}



/* Scroll Bar Override */

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a8a8;
  cursor:pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
  width: 20px;
  cursor:pointer;
}

@media (max-width:1028px){
  ::-webkit-scrollbar-thumb{
      height:1px;
      display:none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:1px;
    display:none;
    border-radius: 1rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:none;
    display:none;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    display:none;
  }
}
